import { createSelector } from 'reselect'

/** @return {?number} (null if not logged in) */
export const selectUserId = state => state.user.getIn(['userInfo', 'userId'], null)

/** @return {string} (empty string if not logged in)*/
export const selectUserName = state => state.user.getIn(['userInfo', 'username'], '')

/** @return {string} (empty string if not logged in)*/
export const selectUserEmail = state => state.user.getIn(['userInfo', 'email'], '')

/** @return {string} (empty string if not logged in)*/
export const selectUserHash = state => state.user.getIn(['userInfo', 'userHash'], '')

/** @return {string} (empty string if not logged in)*/
export const selectUserAlmaIdHash = state => state.user.getIn(['userInfo', 'almaIdHash'], '')

/** @return {?string} (null if not logged in) */
export const selectUserJWT = state => state.user.getIn(['userInfo', 'jwt'], null)

/** @return {boolean} */
export const selectIsUserLoggedIn = state => !!selectUserId(state)

/** @return {string} (logged_in|logged_out) */
export const selectUserLoginStatusGA4 = state => selectUserId(state) ? 'logged_in' : 'logged_out' // Must be this exact format for our GA4

/** @return {string} (loggedin|loggedout) */
export const selectUserLoginStatusADL = state => selectUserId(state) ? 'loggedin' : 'loggedout' // Must be this exact format for AlmaDataLayer

/** @return {Immutable.Map<string,Immutable.Set<number>>} */
export const selectUserCategories = state => state.user.get('categories')

/** @return {Immutable.Set<number>} */
export const selectUserBlacklistedCategoryIds = state => state.user.get('categories').get('blacklisted')

/** @return {Immutable.Set<number>} */
export const selectUserWhitelistedCategoryIds = state => state.user.get('categories').get('whitelisted')

/** @return {Immutable.Set<number>} */
export const selectUserBlacklistedTagIds = state => state.user.get('tags').get('blacklisted')

/** @return {Immutable.Set<number>} */
export const selectUserWhitelistedTagIds = state => state.user.get('tags').get('whitelisted')

/** @return {Immutable.Set<number>} */
export const selectUserBlacklistedSourceIds = state => state.user.get('blacklistedSources')

/** @return {Immutable.Set<number>} */
export const selectUserSubscribedSourceIds = state => state.user.get('subscribedSources')

/**
 * Not in use since 12/2024
 * @todo remove this selector
 */
/** @return {Immutable.Set<string>} */
export const selectUserSubscribedLanguageSlugs = state => state.user.get('subscribedLanguages')

/** @return {number} negative integer or 0 */
export const selectUserVoteFilterThreshold = state => state.user.get('voteFilterThreshold', 0)

/** @return {string} (default|classic|night|matrix) */
export const selectUserPrimaryTheme = state => state.user.get('theme')

/** @return {string} (normal|wide|full) */
export const selectUserLayoutWidth = state => state.user.get('layoutWidth')

/** @return {boolean} */
export const selectIsNightModeEnabled = state => state.user.get('nightMode')

/** @return {Immutable.OrderedSet<string>} */
export const selectUserWordFilters = state => state.user.get('wordFilters')

/** @return {Immutable.OrderedSet<string>} */
export const selectUserSavedSearches = state => state.user.get('savedSearches')

/** @return {Immutable.OrderedSet<CustomFeedRecord>} */
export const selectUserCustomFeeds = state => state.user.get('customFeeds')

/** @return {boolean} */
export const selectShowUserFeedsSidebar = state => {
  return state.user.get('showUserFeedsBoxInSidebar')
}

export const selectUserWhitelistedCategories = createSelector(
  selectUserWhitelistedCategoryIds,
  state => state.categories.categories,
  /** @return {Immutable.Set<CategoryRecord>} */
  (categoryIds, categoriesMap) => {
    return categoriesMap.filter(cat => categoryIds.includes(cat.id)).toSet()
  }
)

export const selectUserBlacklistedCategories = createSelector(
  selectUserBlacklistedCategoryIds,
  state => state.categories.categories,
  /** @return {Immutable.Set<CategoryRecord>} */
  (categoryIds, categoriesMap) => {
    return categoriesMap.filter(cat => categoryIds.includes(cat.id)).toSet()
  }
)

export const selectUserActiveTheme = createSelector(
  selectUserPrimaryTheme,
  selectIsNightModeEnabled,
  /** @return {string} (default|classic|night|matrix) */
  (theme, nightMode) => {
    return nightMode === true ? 'night' : theme
  }
)
