import React, { useEffect, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'

import { fetchUserProfile } from '../userActions'
import '../../styles/settings.pcss'
import { fetchSources } from '../../views/Info/sourcesActions'
import Loading from '../../components/util/Loading'
import SourceItem from './SourceItem'
import UnableToLoad from '../../components/messages/UnableToLoad'
import { selectSourcesOrderedByName, selectSourceLoading, selectSourceFailedToLoad } from '../../selectors/sourcesSelector'
import { selectUserBlacklistedSourceIds, selectUserSubscribedSourceIds } from '../../selectors/userSelector'

const SourceSettingsView = () => {
  const dispatch = useDispatch()

  const loading = useSelector(selectSourceLoading)
  const sources = useSelector(selectSourcesOrderedByName)
  const blacklistedIds = useSelector(selectUserBlacklistedSourceIds)
  const subscribedIds = useSelector(selectUserSubscribedSourceIds)
  const failedToLoadSources = useSelector(selectSourceFailedToLoad)

  useEffect(() => {
    dispatch(fetchSources(60))
    dispatch(fetchUserProfile(5))
  }, [dispatch])


  const handleLabelClick = (refs, key) => () => {
    const element = refs[key].current
    const labelsOffset = 160
    const offsetPosition = element.getBoundingClientRect().top + window.pageYOffset - labelsOffset

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }

  const renderLabels = (labels, refs) => {
    return (
      <div className='sources__labels__list'>
        { labels.map((char) => (
          <button
            type='button'
            className='sources__labels__list-button'
            key={ char }
            onClick={ handleLabelClick(refs, char) }
          >
            <span className='sources__labels__list-button-text'>{ char }</span>
          </button>
        )) }
      </div>
    )
  }

  const groupedSources = sources.reduce((acc, source) => {
    const char = source.name[0].toUpperCase() // Change to capital if the source name does not begin with it (e.g. "eUrheilu")
    acc[char] ??= []
    acc[char].push(source)
    return acc
  }, {})

  const labels = Object.keys(groupedSources).sort()
  const refs = labels.reduce((acc, char) => {
    acc[char] = createRef()
    return acc
  }, {})

  return (
    <div>
      <Helmet title='Lähteet' />
      <section className='content'>
        <div className='profile-settings-info-text'>
          <p>
            Valitse, mitkä uutislähteet haluat piilottaa Amppareista.
            Kun piilotat uutislähteen, et näe sen julkaisuja enää millään Amppareiden uutislistalla.
          </p>
          <p>
            Mikäli lähteellä on maksullisia artikkeleita, voit erikseen valita, haluatko nähdä ne uutislistoilla.
            Oletusarvoisesti maksullista sisältöä ei näytetä Amppareissa.
          </p>
        </div>

        <div className='personal'>
          <div className='sources'>
            <div className='sources__labels-container'>
              { labels.length >= 10 && renderLabels(labels, refs) }
            </div>
            <div className='box-header'>
              <span className='box-header-text'>Lähteet</span>
            </div>
            <Loading loading={ loading }>
              { failedToLoadSources ?
                <UnableToLoad errorText='Lähdelistan haku ei onnistunut.' />
                :
                labels.map((char) => (
                  <div className='sources__list-container' key={ char }>
                    <div className='sources__list-label-container'>
                      <span className='sources__list-label' ref={ refs[char] }>{ char }</span>
                    </div>
                    <ul className='sources__list'>
                      { groupedSources[char].map((source) => (
                        <li className='list-style' key={ source.id }>
                          <SourceItem
                            source={ source }
                            isBlacklisted={ blacklistedIds.includes(source.id) }
                            isSubscribed={ subscribedIds.includes(source.id) }
                            dispatch={ dispatch }
                          />
                        </li>
                      )) }
                    </ul>
                  </div>
                ))
              }
            </Loading>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SourceSettingsView
