import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import {
  changeActiveList,
  fetchNewest,
  fetchNewestBatch,
  fetchNewestNext,
  unqueueItems,
  LIST_NAME_NEWEST,
  LIST_TYPE_MAIN,
} from '../../components/item/itemsActions'
import AbstractListView, { connectGenericProps } from './AbstractListView'
import { fetchPopular } from '../../components/item/popularActions'


@provideHooks({
  fetch: ({ dispatch }) => {

    dispatch(changeActiveList(LIST_TYPE_MAIN, LIST_NAME_NEWEST))

    return Promise.all([
      dispatch(fetchNewest()),
      dispatch(fetchPopular(300)),
    ])
  },
})
export class NewestView extends AbstractListView {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    dispatch: PropTypes.func.isRequired,
    activePage: PropTypes.shape({
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    trendingFailedToLoad: PropTypes.bool,
  }

  refreshList() {
    const { dispatch } = this.props
    dispatch(fetchNewest())
  }

  fetchNext(timestamp) {
    return (dispatch) => dispatch(fetchNewestNext(timestamp))
  }

  handleFetchBatchItems = (batch) => {
    const { dispatch } = this.props
    dispatch(fetchNewestBatch(batch))
  }

  unqueueItems() {
    const { dispatch } = this.props

    dispatch(unqueueItems(LIST_NAME_NEWEST))
  }

  setActiveList() {
    const {dispatch} = this.props

    dispatch(changeActiveList(LIST_TYPE_MAIN, LIST_NAME_NEWEST))
  }

  getMetaDescription() {
    return 'Ampparit.com tarjoaa Suomen kattavimman uutiskatsauksen. Löydät Uusimmat-listalta kaikki uutiset ' +
      'Amppareista juuri nyt. Klikkaa ja löydä kätevästi kaikki tuoreimmat uutiset!'
  }

  getTitle() {
    return 'Uusimmat | Tuoreimmat uutiset | Ampparit.com'
  }

  getH1() {
    return 'Uusimmat | Uutiset'
  }

  getOgTitle() {
    return this.getTitle()
  }
}

export default connect((state) => {
  const { trending } = state
  const newest = state.items.lists.get(LIST_NAME_NEWEST)

  return Object.assign(connectGenericProps(state, newest), {
    activePage: {
      to: '/uusimmat',
      title: 'Kaikki',
    },
    trendingFailedToLoad: trending.get('failedToLoad'),
  })
})(NewestView)
