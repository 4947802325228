import React, { Component } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ListTitle from './ListTitle'
import './itemsListHeader.pcss'

export default class ItemsListHeader extends Component {

  render() {
    const {
      heading,
      children,
      isTagView,
    } = this.props

    return (
      <div className='items-list-header'>
        <ListTitle heading={ heading } isTagView={ isTagView } />
        { children }
      </div>
    )
  }
}

ItemsListHeader.propTypes = exact({
  heading: PropTypes.string.isRequired,
  children: PropTypes.node,
  isTagView: PropTypes.bool,
})
