import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import {
  changeActiveList,
  fetchPaywalled,
  fetchPaywalledBatch,
  fetchPaywalledNext,
  unqueueItems,
  LIST_NAME_PAYWALLED,
  LIST_TYPE_MAIN,
} from '../../components/item/itemsActions'
import AbstractListView, { connectGenericProps } from './AbstractListView'
import { fetchPopular } from '../../components/item/popularActions'


@provideHooks({
  fetch: ({ dispatch }) => {

    dispatch(changeActiveList(LIST_TYPE_MAIN, LIST_NAME_PAYWALLED))

    return Promise.all([
      dispatch(fetchPaywalled()),
      dispatch(fetchPopular(300)),
    ])
  },
})
export class PaywalledView extends AbstractListView {

  static propTypes = { // eslint-disable-line react/prefer-exact-props
    dispatch: PropTypes.func.isRequired,
    activePage: PropTypes.shape({
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    trendingFailedToLoad: PropTypes.bool,
  }

  refreshList() {
    const { dispatch } = this.props
    dispatch(fetchPaywalled())
  }

  fetchNext(timestamp) {
    return (dispatch) => dispatch(fetchPaywalledNext(timestamp))
  }

  handleFetchBatchItems = (batch) => {
    const { dispatch } = this.props
    dispatch(fetchPaywalledBatch(batch))
  }

  unqueueItems() {
    const { dispatch } = this.props

    dispatch(unqueueItems(LIST_NAME_PAYWALLED))
  }

  setActiveList() {
    const {dispatch} = this.props

    dispatch(changeActiveList(LIST_TYPE_MAIN, LIST_NAME_PAYWALLED))
  }

  getMetaDescription() {
    return 'Ampparit.com kokoaa yhteen koko Suomen uutistarjonnan. Nyt myös maksulliset uutiset ' +
      'löydettävissä Amppareista. Klikkaa ja löydä kätevästi kaikki tuoreimmat uutiset!'
  }

  getTitle() {
    return 'Maksulliset | Tuoreimmat uutiset | Ampparit.com'
  }

  getH1() {
    return 'Maksulliset | Uutiset'
  }

  getOgTitle() {
    return this.getTitle()
  }
}

export default connect((state) => {
  const { trending } = state
  const paywalled = state.items.lists.get(LIST_NAME_PAYWALLED)
  return Object.assign(connectGenericProps(state, paywalled), {
    activePage: {
      to: '/maksulliset',
      title: 'Maksulliset',
    },
    trendingFailedToLoad: trending.get('failedToLoad'),
  })
})(PaywalledView)
