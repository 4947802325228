import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import exact from 'prop-types-exact'
import Immutable from 'immutable'
import { Helmet } from 'react-helmet'
import Sticky from 'react-stickynode'
import classnames from 'classnames'
import MoreItems from '../../components/lists/MoreItems'
import { createUrl, basicWildcardMatch } from '../../lib/utils'
import { itemIdsToItems } from '../../components/item/itemsTransformers'
import Loading from '../../components/util/Loading'
import Item from '../../components/item/Item'
import {
  listQueueLimit,
  listSize,
  listStatsUpdateInterval,
} from '../../lib/items'
import ItemsListHeader from '../../components/lists/ItemsListHeader'
import Queue from '../../components/lists/Queue'
import { Ticker } from '../../components/ticker/Ticker'
import createItemsWithLabels from '../../lib/createItemsWithLabels'
import splitItemsToSegments from '../../lib/splitItemsToSegments'
import injectAdsToList from '../../ad/injectAdsToList'
import SidebarMenu from '../../components/layout/SidebarMenu'
import {
  BoxAd,
  PostContentAd,
  MOBILE_LIST_2,
  MOBILE_LIST_3,
  MOBILE_LIST_4,
  MOBILE_LIST_5,
  MOBILE_LIST_X,
  MobileLeaderboardAd,
  MobileListAd,
  AlmaAjoListAd,
  SkyscraperAd,
  SKYSCRAPER_2,
  NativeAd,
  LIST_AD_1,
} from '../../ad/Ad'
import Resize from '../../ad/Resize'
import GeneralSidebar from '../../components/layout/GeneralSidebar'
import '../../styles/items.pcss'
import {
  changeActiveList,
  fetchStatsForItems,
  InitializeSegmentList,
  LIST_NAME_NONE,
  LIST_TYPE_NONE,
} from '../../components/item/itemsActions'
import { selectActivePresetProfileId } from '../../selectors/profilesSelector'
import { selectBreakingNewsTagItem, selectTrendingTagItems } from '../../selectors/tagsSelector'
import { WEBSOCKET_FAILED, WEBSOCKET_RECOVERED } from '../../status/statusReducer'
import { selectIsUserLoggedIn, selectUserBlacklistedTagIds } from '../../selectors/userSelector'
import MainContent from '../../components/wrappers/MainContent'
import { selectDeviceType } from '../../selectors/statusSelector'
import shareImg from '../../assets/ampparit-share-news.jpg'
import CategoryRecommendations from '../../user/CategoryRecommendations'
import { refreshAllAds } from '../../ad/AlmaAd'
import PopularNewsListBox from '../../components/widgets/PopularNewsListBox'
import TopNotification from '../../user/TopNotification'
import SaveTempProfileLink from '../../components/ui/SaveTempProfileLink'
import { CooperationBannerList } from '../../ad/CooperationBanner'
import LoginPromtItem from '../../components/item/LoginPromtItem'
import BreakingNews from '../../components/widgets/BreakingNews'
import TrendingTagNewsListBox from '../../components/widgets/TrendingTagsNewsListBox'


/**
 * 'Abstract' list view that can be extended to create varying news list views.
 * This component should only contain generic news list code. All code specific to any view should
 * live in the extending component.
 *
 * @todo Refactor list views to use composition over inheritance (https://reactjs.org/docs/composition-vs-inheritance.html)
 */
export default class AbstractListView extends Component {
  constructor (props) {
    super(props)

    this.adsUnqueuedItemsCount = 0

    this.state = {
      segments: Immutable.List(),
      itemStatsUpdateInterval: null,
      shouldRefreshList: false,
    }
  }

  static propTypes = exact({
    dispatch: PropTypes.func.isRequired,
    items: ImmutablePropTypes.orderedSet.isRequired,
    loading: PropTypes.bool.isRequired,
    failedToLoad: PropTypes.bool.isRequired,
    loadingNext: PropTypes.bool.isRequired,
    queuedItemsCount: PropTypes.number.isRequired,
    path: PropTypes.string.isRequired,
    tag: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      blacklisted: PropTypes.bool.isRequired,
      whitelisted: PropTypes.bool.isRequired,
    }),
    category: ImmutablePropTypes.record,
    timestamp: PropTypes.number.isRequired,
    listEnd: PropTypes.bool.isRequired,
    notFound: PropTypes.bool,
    listBlacklisted: PropTypes.bool,
    tempProfileModified: PropTypes.bool.isRequired,
    location: ImmutablePropTypes.map.isRequired,
    allItems: ImmutablePropTypes.map.isRequired,
    tickers: ImmutablePropTypes.list.isRequired,
    deviceType: PropTypes.string.isRequired,
    activePresetProfileId: PropTypes.number,
    loggedIn: PropTypes.bool.isRequired,
    itemIds: ImmutablePropTypes.set.isRequired,
    queuedItems: ImmutablePropTypes.map.isRequired,
    itemsQueuedTotal: PropTypes.number.isRequired,
    websocketStatus: PropTypes.string.isRequired,
    emptySegments: ImmutablePropTypes.list.isRequired,
    personalListShouldRefresh: PropTypes.bool.isRequired,
    shouldRefresh: PropTypes.bool.isRequired,
    relatedCategories: ImmutablePropTypes.list.isRequired,
    breakingTopic: ImmutablePropTypes.map.isRequired,
    breakingItems: ImmutablePropTypes.orderedSet.isRequired,
    breakingShouldUpdate: PropTypes.bool.isRequired,
    blacklistedTagIds: ImmutablePropTypes.set.isRequired,
    trendingTags: ImmutablePropTypes.listOf(ImmutablePropTypes.mapContains({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      whitelisted: PropTypes.bool.isRequired,
      blacklisted: PropTypes.bool.isRequired,
    })).isRequired,
    trendingFailedToLoad: PropTypes.bool.isRequired,
  })

  render() {
    const {
      dispatch,
      items,
      loading,
      failedToLoad,
      loadingNext,
      queuedItemsCount,
      path,
      timestamp,
      listEnd,
      notFound,
      listBlacklisted,
      tempProfileModified,
      location,
      allItems,
      tickers,
      deviceType,
      loggedIn,
      tag,
      breakingTopic,
      blacklistedTagIds,
      breakingItems,
      breakingShouldUpdate,
      category,
    } = this.props

    if (notFound === true) {
      return false
    }

    const title = this.getTitle()
    const heading = this.getH1()

    let ogTitle = this.getOgTitle()
    let canonicalUrl = createUrl(path)

    if (location && location.query.share) {
      const sharedItem = allItems.find(item => item.id === location.query.share)
      canonicalUrl = canonicalUrl + '?share=' + location.query.share
      ogTitle = (sharedItem && sharedItem.get('title')) ?? 'Jaettua uutista ei löydy'
    }

    const { segments } = this.state

    const createItem = item => {
      return (
        <Item
          key={ item.get('id') }
          item={ item }
          timestamp={ timestamp }
          onFetchBatchItems={ this.handleFetchBatchItems }
          dispatch={ dispatch }
          loggedIn={ loggedIn }
          isTagView={ !!tag }
        />
      )
    }

    const segmentedItems = splitItemsToSegments(createItemsWithLabels(items, timestamp, createItem), segments)
    const topTicker = tickers.find(ticker =>
      !ticker.get('closed') && ticker.get('position') === 'top' && ticker.get('devices').contains(deviceType) &&
      ticker.get('pages').some(page => basicWildcardMatch(page, path))
    )

    const showBreakingTopic =
      breakingTopic &&
      !blacklistedTagIds.contains(breakingTopic.get('id')) &&
      breakingItems.size > 0 &&
      path === '/'

    return (
      <div className={ classnames('container', 'item-list', { 'list-blacklisted': listBlacklisted } ) }>
        <Helmet
          title={ title }
          heading={ heading }
          meta={ [
            { name: 'description', content: this.getMetaDescription() },
            { property: 'og:title', content: ogTitle },
            { property: 'og:image', content: this.getOgImage() },
            { property: 'og:description', content: this.getOgDescription() },
            { property: 'og:url', content: canonicalUrl },
          ] }
          link={ [
            {rel: 'canonical', href: canonicalUrl},
          ] }
        />

        <div className='main-container'>
          <SidebarMenu />

          <MainContent className={ classnames({'content_no-bg': !loading}) }>
            <div id='sticky-ad-bottom-boundary' >

              { listBlacklisted &&
                this.renderBlacklistedNotification()
              }

              { this.renderInstructions() }

              { !loggedIn && tempProfileModified &&
                <TopNotification>
                  <SaveTempProfileLink dispatch={ dispatch } />
                </TopNotification>
              }

              { !showBreakingTopic ?
                null :
                <BreakingNews
                  dispatch={ dispatch }
                  breakingTopic={ breakingTopic }
                  breakingItems={ breakingItems }
                  shouldUpdate={ breakingShouldUpdate }
                />
              }

              <ItemsListHeader
                heading={ heading }
                isTagView={ !!tag }
              >

                { this.renderListOptions() }

                { !loading &&
                  <Queue
                    reload={ this.requireRefresh() }
                    count={ queuedItemsCount }
                    onUnqueueItems={ this.handleUnqueueItems }
                    isTagView={ !!tag }
                  />
                }

              </ItemsListHeader>

              { topTicker && (
                <Ticker ticker={ topTicker } dispatch={ dispatch } />
              ) }

              { this.renderNewsListWidgets() }

              { failedToLoad &&
                this.renderFailedToLoad()
              }

              { !failedToLoad && !loading && this.isEmpty() ? (
                this.renderEmpty()
              ) : (
                this.renderNewsList(segmentedItems)
              ) }

            </div>
            { segmentedItems.size === 1 && items.size >= listSize && !listEnd && (
              <MoreItems onMoreItemsClick={ this.handleClickMore } loading={ loadingNext } />
            ) }
          </MainContent>

          <div className='sidebar-container'>
            <GeneralSidebar category={ category } tag={ tag } />
          </div>
        </div>

        { segmentedItems.size > 1 && (
          <div className='main-container' id='sticky-segment-ad-bottom-boundary'>
            <div className='menu-container'>
              <Sticky top={ 50 } bottomBoundary='#sticky-segment-ad-bottom-boundary'>
                <SkyscraperAd adUnitId={ SKYSCRAPER_2 } />
              </Sticky>
            </div>
            <div className='fold-container outer-container'>
              { segmentedItems.rest().map((segmentItems, i) => {
                const isLast = i === segmentedItems.rest().size - 1
                return (
                  <div className='main-container' key={ i }>
                    <div className='content content_no-bg'>
                      { injectAdsToList(segmentItems, this.getAdsForFollowingSegments(i)) }

                      { isLast && !listEnd && (
                        <MoreItems onMoreItemsClick={ this.handleClickMore } loading={ loadingNext } />
                      ) }
                    </div>
                  </div>
                )
              }
              ) }
            </div>
            <div className='sidebar-container' >
              <Sticky top={ 50 } bottomBoundary='#sticky-segment-ad-bottom-boundary'>
                <BoxAd adUnitId='almad-aside-right-8' />
              </Sticky>
            </div>
          </div>
        ) }

        { !loading && path === '/' &&
          <PostContentAd />
        }
      </div>
    )
  }

  componentDidMount() {
    this.setActiveList()

    this.setState({
      itemStatsUpdateInterval: setInterval(() => {
        if (window.document.hidden) return
        this.updateListItemStats()
      }, listStatsUpdateInterval),
    })

    window.document.addEventListener('visibilitychange', this.handleDocumentVisibilityStateChange)
  }

  componentWillUnmount() {
    window.document.removeEventListener('visibilitychange', this.handleDocumentVisibilityStateChange)
    clearInterval(this.state.itemStatsUpdateInterval)
    this.unsetActiveList()
  }

  handleDocumentVisibilityStateChange = () => {
    if (!window.document.hidden) this.updateListItemStats()
  }

  updateListItemStats = (updateQueue = false) => {
    const { dispatch, itemIds, queuedItems } = this.props
    const ids = updateQueue === true ? queuedItems.concat(itemIds) : itemIds
    if (ids && ids.size > 0) {
      dispatch(fetchStatsForItems(ids.toArray()))
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = {}

    if (nextProps.emptySegments) {
      newState.segments = Immutable.List()
    }

    if (nextProps.personalListShouldRefresh) {
      if (nextProps.personalListShouldRefresh && !prevState.personalListShouldRefresh) {
        newState.shouldRefreshList = true
      }
    } else {
      if (nextProps.shouldRefresh && !prevState.shouldRefresh) {
        newState.shouldRefreshList = true
      }
    }

    return newState
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.emptySegments) {
      this.props.dispatch(InitializeSegmentList(false))
    }
    if (this.state.shouldRefreshList) {
      this.refreshList()
      this.setState({ shouldRefreshList: false })
    }
    this.onListDidUpdate(prevProps, this.props)
  }

  onListDidUpdate(prevProps, props) {
    // optionally override
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.loading !== this.props.loading ||
      nextProps.loadingNext !== this.props.loadingNext ||
      nextProps.timestamp !== this.props.timestamp ||
      nextProps.queuedItemsCount !== this.props.queuedItemsCount ||
      nextProps.websocketStatus !== this.props.websocketStatus ||
      nextProps.tempProfileModified !== this.props.tempProfileModified ||
      nextProps.relatedCategories !== this.props.relatedCategories ||
      nextProps.tickers !== this.props.tickers ||
      !nextProps.items.equals(this.props.items) ||
      nextProps.activePresetProfileId !== this.props.activePresetProfileId ||
      (nextState.shouldRefreshList && !this.state.shouldRefreshList)
    )
  }

  refStickySkyscraper = (ad) => {
    this.stickySkyscraper = ad
  }

  refStickyBox = (ad) => {
    this.stickyBox = ad
  }

  refDeskAd = (ad) => {
    this.deskAd = ad
  }

  handleClickMore = () => {
    const { dispatch, items } = this.props

    if (window.ALMA.ad.loadAd) {
      window.ALMA.ad.loadAd('almad-aside-left-2', {reload: true})
      window.ALMA.ad.loadAd('almad-aside-right-5', {reload: true})
      window.ALMA.ad.loadAd('almad-post-content-1', {reload: true})
    }

    this.setState({
      segments: this.state.segments.push(items.last().get('id')),
    })

    dispatch(this.fetchNext(items.minBy(item => item.get('timestamp')).get('timestamp')))

  }

  refreshList() {
    console.warn('implement refreshList in the extending component')
  }

  fetchNext() {
    return () => Promise.reject('implement fetchNext in the extending component')
  }

  handleAdsOnUnqueueItems = () => {
    const { queuedItemsCount } = this.props

    this.adsUnqueuedItemsCount += queuedItemsCount

    if (this.adsUnqueuedItemsCount >= 5) {
      this.adsUnqueuedItemsCount = 0
      if (window.initAlmaAds) {
        window.initAlmaAds()
        refreshAllAds()
      }
    }
  }

  handleUnqueueItems = () => {
    this.handleAdsOnUnqueueItems()

    if (this.requireRefresh()) {
      this.refreshList()
    } else {
      this.unqueueItems()
      this.updateListItemStats(true)
    }
  }

  unqueueItems() {
    console.warn('implement unqueueItems in the extending component')
  }

  getTitle() {
    console.warn('implement getTitle in the extending component')

    return 'Uutiset | Tuoreimmat uutiset | Ampparit.com'
  }

  getH1() {
    console.warn('implement getH1 in the extending component')

    return 'Uutiset'
  }

  getMetaDescription() {
    // Optionally override this in the extending component
    return 'Ampparit.com tarjoaa Suomen kattavimman uutiskatsauksen. ' +
           'Klikkaa ja löydä kätevästi kaikki tuoreimmat uutiset!'
  }

  getOgTitle() {
    // Optionally override this in the extending component
    return this.getTitle() + ' | Tuoreimmat uutiset | Ampparit.com'
  }

  getOgDescription() {
    // Optionally override this in the extending component
    return this.getMetaDescription()
  }

  getOgImage() {
    // Optionally override this in the extending component
    return createUrl(shareImg)
  }

  renderInstructions() {
    // optionally implement this in extending component to display content before the news list
  }

  renderEmpty() {
    // optionally implement this in extending component to display something when this.isEmpty() is true
  }

  renderFailedToLoad() {
    // optionally implement this in extending component to display something when props.failedToLoad is true
  }

  renderBlacklistedNotification() {
    // optionally implement this in extending component to display a box notification at the top of the list
    // if the category/tag is blacklisted by user
  }

  renderNewsListWidgets() {
    // optionally implement this in extending component to display widgets between the list header and news list
  }

  renderListOptions() {
    // optionally implement this in extending component to display list options
  }

  renderNewsList(segmentedItems) {
    const { loading } = this.props
    return (
      <Loading loading={ loading }>
        { injectAdsToList(segmentedItems.first(), this.getAdsForFirstSegment()) }
      </Loading>
    )
  }

  setActiveList() {
    console.warn('implement setActiveList in the extending component')
  }

  unsetActiveList() {
    this.props.dispatch(changeActiveList(LIST_TYPE_NONE, LIST_NAME_NONE))
  }

  isEmpty() {
    const { items } = this.props

    return items.size <= 0
  }

  requireRefresh() {
    const { itemsQueuedTotal, websocketStatus } = this.props
    return websocketStatus === WEBSOCKET_FAILED || websocketStatus === WEBSOCKET_RECOVERED || itemsQueuedTotal >= listQueueLimit
  }

  getAdsForFirstSegment() {
    const {
      path,
      category,
      dispatch,
      loggedIn,
      trendingTags,
      trendingFailedToLoad,
      tag,
    } = this.props

    return [{

      component: (
        <MobileLeaderboardAd />
      ),
      index: 4,
    },
    {
      component: (
        !category && !loggedIn ? (
          <LoginPromtItem />
        ) : null
      ),
      index: 6,
    },
    {
      component: (
        <Fragment>
          <TrendingTagNewsListBox
            trending={ trendingTags }
            failedToLoad={ trendingFailedToLoad }
            wrappingClass='news-list-box'
          />
          <PopularNewsListBox category={ category } tag={ tag } />
        </Fragment>
      ),
      index: 8,
    },
    {
      component: (
        <NativeAd adUnitId={ LIST_AD_1 } />
      ),
      index: 12,
    },
    {
      component: (
        <MobileListAd adUnitId={ MOBILE_LIST_2 } />
      ),
      index: 16,
    },
    {
      component: (
        <CategoryRecommendations dispatch={ dispatch } />
      ),
      index: 15,
    },
    {
      component: (
        <div className='placeholder-wrapper'>
          <Resize minBreakpoint={ 897 }>
            <div id='almad-list-2' path={ path } /> { /* This can be video ad */ }
          </Resize>
        </div>
      ),
      index: 20,
    },
    {
      component: (
        <CooperationBannerList title='Alma Ajon kumppani' path={ path }>
          <AlmaAjoListAd adUnitId={ MOBILE_LIST_3 } />
        </CooperationBannerList>
      ),
      index: 28,
    },
    {
      component: (
        <CooperationBannerList title='Alma Ajon kumppani' path={ path }>
          <AlmaAjoListAd adUnitId={ MOBILE_LIST_4 } />
        </CooperationBannerList>
      ),
      index: 36,
    },
    {
      component: (
        <MobileListAd adUnitId={ MOBILE_LIST_5 } />
      ),
      index: 40,
    },
    {
      component: (
        path === '/' &&  (
          <CooperationBannerList
            src='https://feed.etuovi.com/etuovi/html/eo-2022-300x300-kaupungit_ampparit.html'
            title='Yhteistyössä Etuovi.com:' iframeTitle='Etuovi.com mainos' path={ path }
          />
        )
      ),
      index: 54,
    },
    {
      component: (
        <CooperationBannerList title='Alma Ajon kumppani' path={ path }>
          <AlmaAjoListAd adUnitId='almad-list-6' />
        </CooperationBannerList>
      ),
      index: 64,
    },
    {
      component: (
        <MobileListAd adUnitId='almad-list-7' />
      ),
      index: 72,
    }]
  }

  getAdsForFollowingSegments(segment) {
    /**
     * After 'Lisää uutisia - More News' click set ad ids for following ads.
     * Decided for viewabililty reasons to limit ad ids to 7 maximum.
     */

    let upperAdId = 8
    let lowerAdId = 9

    if (segment++) {
      upperAdId = upperAdId + 2
      lowerAdId = lowerAdId + 2
    }

    return [{
      component: (
        segment < 3 ? <MobileListAd adUnitId={ MOBILE_LIST_X + upperAdId } /> : null // Set null after 3rd "Lisää uutisia" button click
      ),
      index: 8,
    },
    {
      component: (
        segment < 3 ? <MobileListAd adUnitId={ MOBILE_LIST_X + lowerAdId } /> : null // Set null after 3rd "Lisää uutisia" button click
      ),
      index: 35,
    }]
  }
}

/**
 * Return generic set of Redux connect props used across all the AbstractListView.jsx extending lists.
 * @param state
 * @param list
 * @returns {{}}
 */
export function connectGenericProps(state, list) {
  return {
    itemIds: list.get('itemIds'),
    items: itemIdsToItems(state, list.get('itemIds'), list.get('batches')),
    allItems: state.items.items,
    queuedItems: list.get('itemQueueIds'),
    queuedItemsCount: list.get('itemQueueIds').size,
    itemsQueuedTotal: list.get('itemsQueuedTotal'),
    websocketStatus: state.status.get('websocket'),
    loading: list.get('loading'),
    failedToLoad: list.get('failedToLoad'),
    loadingNext: list.get('loadingNext'),
    listEnd: list.get('listEnd'),
    shouldRefresh: list.get('shouldRefresh'),
    timestamp: state.time.get('timestamp'),
    emptySegments: state.items.shouldInitializeListSegments,
    tickers: state.tickers.tickers,
    deviceType: selectDeviceType(state),
    loggedIn: selectIsUserLoggedIn(state),
    trendingFailedToLoad: state.trending.get('failedToLoad'),
    trendingTags: selectTrendingTagItems(state),
    breakingTopic: selectBreakingNewsTagItem(state),
    breakingItems: itemIdsToItems(state, state.breakingNews.get('itemIds')),
    breakingShouldUpdate: state.breakingNews.get('shouldUpdate'),
    blacklistedTagIds: selectUserBlacklistedTagIds(state),
    activePresetProfileId: selectActivePresetProfileId(state),
  }
}
